import {EsgTypes} from "../../../../types/research-list";

export const mapStringToNumber = (value: string): number => {
    const mapping: Record<string, number> = {
        "Empty": 0,
        "Yes": 1,
        "No": 2,
        "NA": 3
    };
    return mapping[value] ?? 0;
};

export const flattenEsgData = (esgData: EsgTypes): Record<string, any> => {
    const flattenedData: Record<string, any> = {};
    Object.keys(esgData).forEach((key) => {
        const esgKey = key as keyof EsgTypes;
        flattenedData[esgKey] = mapStringToNumber((esgData[esgKey] as any).value);
    });
    return flattenedData;
};