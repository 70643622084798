import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {IFormInputs} from '../../../types/shared';
import {Box, CircularProgress, Grid} from '@mui/material';
import OptionSelect from '../../../components/option-select/option-select';
import {useDataProcessingContext} from '../../../context/data-processing-context';
import {useUIControlContext} from '../../../context/ui-control-context';
import {columns} from '../../../data/columns/research-list/data-for-columns';
import Toggle from '../../../components/toggle/toggle';
import SimpleBackdrop from '../../../components/backdrop/backdrop';
import InfoPage from '../../info/info-page';
import CustomTable from '../../../components/custom-table/custom-table';
import {useNavigate} from 'react-router-dom';
import TextInput from '../../../components/text-input/text-input';
import {Funds} from '../../../types/research-list';
import axios from 'axios';
import Loading from '../../loading/loading';
import {useSharedDataContext} from '../../../context/shared-data-context';
import CustomButton from '../../../components/custom-button/custom-button';
import {useStore} from '../../../store';
import dayjs from 'dayjs';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';
import Toast from '../../../components/toast/toast';
import Stack from '@mui/material/Stack';

function ResearchList() {
    document.title = `AppHub - Dqr - Research List`;
    const accessToken = useStore((state) => state.accessToken);
    const [researchListData, setResearchListsData] = useState<Funds[]>([]);
    const [loadingResaerchList, setLoadingResaerchList] =
        useState<boolean>(false);
    const [filteredResearchListData, setFilteredResearchListData] = useState<
        Funds[]
    >([]);

    const {selected, setSelected} = useDataProcessingContext();
    const {
        openBackdrop,
        handleLogOut,
        loading,
        setLoading,
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        isDialogOpen,
        handleOpenDialog,
        handleCloseDialog,
        handleManageToast,
    } = useUIControlContext();

    const {
        peerGroupData,
        peerGroupNames,
        afbStatusData,
        afbStatusNames,
        handleGetPeerGroup,
        baseUrl,
        handleGetAfbStatusGroup,
        handleCreateFund,
    } = useSharedDataContext();

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: {
            family: 'Fund Family',
            peerGroup: '',
            afbStatus: '',
            analyst: 'Example',
            insightFunds: false,
            searchValue: '',
            entityName: '',
            entityIsin: '',
        },
    });
    const peerGroupName = watch('peerGroup');
    const afbStatusName = watch('afbStatus');
    const insightFunds = watch('insightFunds');

    const navigate = useNavigate();
    const handleNavigate = useCallback(
        (id: string | number) => {
            navigate(`/dqr/research-list/${id}`);
        },
        [navigate]
    );

    const handleGetPeerGroupId = useCallback(
        (peerGroupName: string) => {
            if (peerGroupData) {
                return peerGroupData.find(
                    (peer) => peer?.name === peerGroupName
                )?.id;
            }
        },
        [peerGroupData]
    );

    const handleGetAfbStatusId = useCallback(
        (afbStatusName: string) => {
            if (afbStatusData) {
                return afbStatusData.find(
                    (status) => status?.name === afbStatusName
                )?.id;
            }
        },
        [afbStatusData]
    );

    const handleFilterResearchData = useCallback(() => {
        const peerGroupId = peerGroupName
            ? handleGetPeerGroupId(peerGroupName)
            : null;
        const afbStatusId = afbStatusName
            ? handleGetAfbStatusId(afbStatusName)
            : null;

        const insightFilteredData = insightFunds
            ? researchListData.filter((fund) => fund.isInsight)
            : researchListData;

        if (!peerGroupName && !afbStatusName) {
            setFilteredResearchListData(insightFilteredData);
        } else {
            const filteredData = insightFilteredData.filter((fund) => {
                const matchesPeerGroup =
                    peerGroupId !== null
                        ? fund.peerGroup_Id === peerGroupId
                        : true;
                const matchesAfbStatus =
                    afbStatusId !== null
                        ? fund.status_Id === afbStatusId
                        : true;
                return matchesPeerGroup && matchesAfbStatus;
            });

            setFilteredResearchListData(filteredData);
        }
    }, [
        peerGroupName,
        afbStatusName,
        insightFunds,
        researchListData,
        handleGetPeerGroupId,
        handleGetAfbStatusId,
    ]);

    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'peerGroup',
                data: peerGroupNames,
                labelId: 'select-peer-group-label',
                label: true,
                selectId: 'select-peer-group',
                labelName: 'Peer Group',
            },
            {
                control: control,
                name: 'afbStatus',
                data: afbStatusNames,
                labelId: 'select-afb-status-label',
                label: true,
                selectId: 'select-afb-status',
                labelName: 'AFB Status',
            },
        ],
        [control, peerGroupNames, afbStatusNames]
    );

    const rows = useMemo(() => {
        return filteredResearchListData.map(
            ({name, peerGroup_Id, factSheetDate, status_Id, id}) => {
                return {
                    id: id,
                    fundFamily: name,
                    peerGroup: peerGroupData.find(
                        (peer) => peer.id === peerGroup_Id
                    )?.name,
                    factsheetDate: factSheetDate
                        ? dayjs(factSheetDate).format('DD/MM/YYYY')
                        : 'No date available',
                    afbStatus: afbStatusData.find(
                        (status) => status.id === status_Id
                    )?.name,
                };
            }
        );
    }, [filteredResearchListData, peerGroupData, afbStatusData]);

    const handleClearFilter = useCallback(() => {
        reset();
    }, [reset]);

    const onSubmitSearchFund: SubmitHandler<IFormInputs> = useCallback(
        async ({searchValue}) => {
            const url = `${baseUrl}Fund/Filter`;
            const data = {
                SearchText: searchValue?.toUpperCase(),
                pageSize: 500
            };
            setLoading(true);
            try {
                const response = await axios.post(url, data, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setResearchListsData(response?.data?.data?.data);
                if (response?.data?.data?.data.length === 0) {
                    handleManageToast(
                        'error',
                        'There are no results with that criteria'
                    );
                }
                console.log('response research list data', response);
            } catch (error) {
                console.error('Error research list request:', error);
                handleManageToast(
                    'error',
                    'There was an error trying to search for the fund'
                );
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                }
            } finally {
                setLoading(false);
            }
        },
        [
            reset,
            setLoading,
            baseUrl,
            accessToken,
            handleManageToast,
            setResearchListsData,
            handleLogOut,
        ]
    );

    useEffect(() => {
        setLoadingResaerchList(true);
        handleGetPeerGroup();
        handleGetAfbStatusGroup();
        setLoadingResaerchList(false);
    }, [handleGetPeerGroup, handleGetAfbStatusGroup, setLoadingResaerchList]);

    useEffect(() => {
        handleFilterResearchData();
    }, [handleFilterResearchData]);
    useEffect(() => {
        if (!isDialogOpen) reset();
    }, [isDialogOpen, reset]);

    const handleNavigateToReviews = useCallback(
        (
            fundId: string
        ) => {
            navigate(`/dqr/research-list/${fundId}/reviews`);
        },
        [navigate]
    );
    const actionsData = useMemo(
        () => [
            {
                status: 'NoResults',
                color: 'warning',
                buttonText: 'Reviews',
                actionType: 'reportListAction',
                actionEvent: (fundId: string) =>
                    handleNavigateToReviews(fundId),
            },
        ],
        [handleNavigateToReviews]
    );

    if (loadingResaerchList) {
        return <Loading/>;
    }

    return (
        <>
            <Box
                component={isDialogOpen ? 'section' : 'form'}
                onSubmit={handleSubmit(onSubmitSearchFund)}
                sx={{width: '100%'}}
            >
                <Grid display='flex' gap={2} flexWrap={'wrap'}>
                    {selectData?.map(
                        ({
                             control,
                             name,
                             data,
                             labelId,
                             label,
                             selectId,
                             labelName,
                         }) => {
                            const sortedData = [...data].sort((a, b) => a.localeCompare(b));
                            return (
                                <Grid item sx={{width: '180px'}} key={name}>
                                    <OptionSelect
                                        control={control}
                                        name={name}
                                        data={sortedData}
                                        labelId={labelId}
                                        label={label}
                                        selectId={selectId}
                                        labelName={labelName}
                                    />
                                </Grid>
                            );
                        }
                    )}

                    <Toggle
                        control={control}
                        name='insightFunds'
                        labelName='View only insight funds'
                    />

                    <CustomButton
                        onClick={handleClearFilter}
                        type='button'
                        color='warning'
                        variant='contained'
                        size='large'
                        text={'Clear filters'}
                    />
                </Grid>
                <Grid
                    flexWrap={'nowrap'}
                    container
                    gap={1}
                    sx={{
                        width: '100%',
                        marginBottom: -1,
                        marginTop: 2,
                    }}
                >
                    <Grid item sx={{flexGrow: 1}}>
                        <TextInput
                            name='searchValue'
                            control={control}
                            labelName='Search by name or ISIN'
                            ruleRequired={!isDialogOpen}
                            minLengthErrorValue={3}
                            minLengthError='Must be at least 3 characters'
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton
                            type='submit'
                            color='success'
                            variant='contained'
                            size='large'
                            text={'Search'}
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton
                            type='button'
                            color='warning'
                            variant='contained'
                            size='large'
                            onClick={handleOpenDialog}
                            text={'New Fund'}
                        />
                    </Grid>
                </Grid>
                <Grid container gap={2} flexDirection='column'>
                    {loading && !isDialogOpen && <Loading/>}
                    {filteredResearchListData.length > 0 ? (
                        <Grid item container gap={2}>
                            {filteredResearchListData &&
                                filteredResearchListData.length > 0 && (
                                    <Grid
                                        item
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <CustomTable
                                            handleNavigate={
                                                handleNavigate
                                            }
                                            useCheckbox={false}
                                            selected={selected}
                                            setSelected={setSelected}
                                            columns={columns}
                                            rows={rows}
                                            actionsData={actionsData}
                                        />
                                    </Grid>
                                )}
                        </Grid>
                    ) : (
                        <InfoPage
                            message={
                                'The are no funds to show, search for one'
                            }
                        />
                    )}
                </Grid>
            </Box>
            <CustomDialog
                submitForm={handleSubmit(handleCreateFund)}
                isAForm
                open={isDialogOpen as boolean}
                title='New Fund'
                text=''
                onClose={handleCloseDialog}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialog}
                                text='CANCEL'
                            />
                            <CustomButton text='CREATE' type='submit'/>
                        </>
                    )
                }
            >
                {loading ? (
                    <Box display='flex' justifyContent='center'>
                        <CircularProgress/>
                    </Box>
                ) : (
                    <Grid
                        container
                        flexDirection={'column'}
                        gap={2}
                        marginTop={'10px'}
                    >
                        <Grid item>
                            <Stack direction="column" spacing={2}>
                                <TextInput
                                    error={!!errors.entityName}
                                    ruleRequired
                                    name='entityName'
                                    control={control}
                                    labelName='Fund Name'
                                />
                                <TextInput
                                    error={!!errors.entityIsin}
                                    ruleRequired
                                    name='entityIsin'
                                    control={control}
                                    labelName='Isin'
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                )}
            </CustomDialog>

            <SimpleBackdrop
                openBackdrop={openBackdrop as boolean}
            />
            <Toast
                handleCloseToast={handleCloseToast as () => void}
                openToast={openToast as boolean}
                severity={alertSeverity}
                message={alertMessage}
            />
        </>
    );
}

export default ResearchList;
