import React from 'react';
import {Control} from 'react-hook-form';
import {IFormInputs} from '../../../../types/shared';
import {Grid} from '@mui/material';
import StarsRating from '../../../../components/stars-rating/stars-rating';
import CustomTextarea from '../../../../components/custom-textarea/custom-textarea';

interface Props {
    readonly control: Control<IFormInputs>;
    readonly investmentProcessLastUpdate?: string;
    readonly investmentProcessUpdateName?: string;
}

function InvestmentProcess({
                               control,
                               investmentProcessLastUpdate,
                               investmentProcessUpdateName,
                           }: Props) {
    return (
        <Grid container flexDirection={'column'} gap={2} marginTop={'20px'}>
            <Grid item>
                <StarsRating control={control} name='investmentProcessStars'/>
            </Grid>
            <Grid item>
                <CustomTextarea
                    name='investmentProcess'
                    control={control}
                    labelName='Investment Process'
                    updateDate={investmentProcessLastUpdate}
                    updateName={investmentProcessUpdateName}
                />
            </Grid>
        </Grid>
    );
}

export default InvestmentProcess;
