import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
    readonly errorMessage: string;
    readonly errorCode?: string;
    readonly minHeight?: string;
}

function ErrorPage({errorMessage, errorCode, minHeight = '100vh'}: Props) {
    document.title = `AppHub - Error`;
    return (
        <Box
            sx={{
                minHeight: minHeight,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <Typography sx={{marginBottom: '15px'}}> {errorCode}</Typography>
            <Typography>
                {errorMessage}
            </Typography>
        </Box>
    );
}

export default ErrorPage;
