import { Chip, ChipPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

interface Props {
    readonly label: string | undefined;
    readonly color:
        | OverridableStringUnion<
              | 'default'
              | 'primary'
              | 'secondary'
              | 'error'
              | 'info'
              | 'success'
              | 'warning',
              ChipPropsColorOverrides
          >
        | undefined;
}

function ChipStatus({ label, color }: Props) {
    return <Chip label={label} color={color} />;
}

export default ChipStatus;
