import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

interface Props {
    readonly openBackdrop: boolean;
}

export default function SimpleBackdrop({
    openBackdrop,
}: Props) {
    return (
        <Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
            >
                <CircularProgress color='primary' />
            </Backdrop>
        </Box>
    );
}
