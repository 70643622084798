import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { TableSortLabel } from '@mui/material';
import React, {useCallback} from 'react';
import { CustomTableHeadProps } from '../../../types/custom-table';
import { Columns, ColumnsName } from '../../../types/shared';
import SearchField from "../../search-field/search-field";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${visuallyHidden}`]: {
        border: 0,
    },
}));

const CustomTableHead: React.FC<CustomTableHeadProps> = (props) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        columns,
        useCheckbox,
        control,
        enableSearch = false,
    } = props;

    const createSortHandler = useCallback(
        (property: keyof ColumnsName) => () => {
            onRequestSort(property);
        },
        [onRequestSort]
    );

    const firstColumnCheckbox = useCheckbox && (
        <StyledTableCell padding="checkbox">
            <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                    'aria-label': 'select all',
                }}
            />
        </StyledTableCell>
    );

    const BoldTableCell = styled(TableCell)({
        fontWeight: 'bold',
    });

    const StyledTableHead = styled(TableHead)({
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        backgroundColor: 'background.paper',
    });

    return (
        <StyledTableHead>
            <TableRow>
                {firstColumnCheckbox}
                {columns.map(({ field, headerName }: Columns) => (
                    <BoldTableCell
                        key={field}
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingLeft: useCheckbox ? 2 : undefined,
                            paddingRight: useCheckbox ? 2 : undefined,
                            paddingTop: useCheckbox ? 0 : undefined,
                            paddingBottom: useCheckbox ? 0 : undefined,
                        }}
                        sortDirection={orderBy === field ? (order as 'asc' | 'desc') : false}
                    >
                        <TableSortLabel
                            active={orderBy === field}
                            direction={orderBy === field ? (order as 'desc') : 'asc'}
                            onClick={createSortHandler(field)}
                        >
                            {headerName}
                        </TableSortLabel>
                    </BoldTableCell>
                ))}
            </TableRow>

            {enableSearch && (
                <TableRow>
                    {columns.map(({ field }) => (
                        <TableCell key={field}
                                   sx={{
                                       padding: 0,
                                       paddingLeft: 1,
                                       paddingRight: 1,
                                       paddingTop: 0,
                                       paddingBottom: 1,
                                   }}>
                            <SearchField
                                placeholder={`Search`}
                                control={control}
                                name={String(field)}
                            />
                        </TableCell>
                    ))}
                </TableRow>
            )}
        </StyledTableHead>
    );
};

export default CustomTableHead;