import React from 'react';
import {Control} from 'react-hook-form';
import {IFormInputs} from '../../../../types/shared';
import {Grid} from '@mui/material';
import CustomTextarea from '../../../../components/custom-textarea/custom-textarea';

interface Props {
    readonly control: Control<IFormInputs>;
    readonly assessmentLastUpdate?: string;
    readonly assessmentLastUpdateName?: string;
}

function Assessment({control, assessmentLastUpdate, assessmentLastUpdateName}: Props) {
    return (
        <Grid container flexDirection={'column'} gap={2} marginTop={'20px'}>
            <Grid item>
                <CustomTextarea
                    name='assessment'
                    control={control}
                    labelName='Assessment'
                    updateDate={assessmentLastUpdate}
                    updateName={assessmentLastUpdateName}
                />
            </Grid>
        </Grid>
    );
}

export default Assessment;
