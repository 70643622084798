import React, {useMemo} from 'react';
import {Control} from 'react-hook-form';
import {IFormInputs} from '../../../../types/shared';
import {Grid, Typography} from '@mui/material';
import CheckboxComponent from '../../../../components/checkbox/checkbox';
import ButtonGroup from '../../../../components/button-group/button-group';
import {scoreCardOption} from '../../../../data/shared/data-for-button-group';
import CustomTextarea from '../../../../components/custom-textarea/custom-textarea';

interface Props {
    readonly control: Control<IFormInputs>;
    readonly aumConcentrationLastUpdate?: string;
    readonly aumConcentrationUpdatedName?: string;
    readonly externalAuditorLastUpdate?: string;
    readonly externalAuditorUpdatedName?: string;
    readonly aumStabilityLastUpdate?: string;
    readonly aumStabilityUpdatedName?: string;
    readonly administratorLastUpdate?: string;
    readonly administratorUpdatedName?: string;
    readonly custodianLastUpdate?: string;
    readonly custodianUpdatedName?: string;
}

function ScoreCard({
                       control,
                       aumConcentrationLastUpdate,
                       aumConcentrationUpdatedName,
                       externalAuditorLastUpdate,
                       externalAuditorUpdatedName,
                       aumStabilityLastUpdate,
                       aumStabilityUpdatedName,
                       administratorLastUpdate,
                       administratorUpdatedName,
                       custodianLastUpdate,
                       custodianUpdatedName,
                   }: Props) {
    const textAreaData = useMemo(
        () => [
            {
                control: control,
                name: 'aumConcentration',
                labelName: 'AUM Concentration',
                updateDate: aumConcentrationLastUpdate,
                updateName: aumConcentrationUpdatedName,
            },
            {
                control: control,
                name: 'auditor',
                labelName: 'Auditor',
                updateDate: externalAuditorLastUpdate,
                updateName: externalAuditorUpdatedName,
            },
            {
                control: control,
                name: 'stability',
                labelName: 'Stability',
                updateDate: aumStabilityLastUpdate,
                updateName: aumStabilityUpdatedName,
            },
            {
                control: control,
                name: 'administrator',
                labelName: 'Administrator',
                updateDate: administratorLastUpdate,
                updateName: administratorUpdatedName,
            },
            {
                control: control,
                name: 'custodian',
                labelName: 'Custodian',
                updateDate: custodianLastUpdate,
                updateName: custodianUpdatedName,
            }
        ],
        [
            control,
            aumConcentrationLastUpdate,
            aumConcentrationUpdatedName,
            externalAuditorLastUpdate,
            externalAuditorUpdatedName,
            aumStabilityLastUpdate,
            aumStabilityUpdatedName,
            administratorLastUpdate,
            administratorUpdatedName,
            custodianLastUpdate,
            custodianUpdatedName,
        ]
    );

    const buttonGroupButtonData = useMemo(
        () => [
            {
                control: control,
                name: 'oecdRegulator',
                data: scoreCardOption,
                label: 'OECD Regulator',
            },
            {
                control: control,
                name: 'independentAdmin',
                data: scoreCardOption,
                label: 'Independent Admin',
            },
            {
                control: control,
                name: 'independentCustodian',
                data: scoreCardOption,
                label: 'Independent Custodian',
            },
            {
                control: control,
                name: 'thirdPartyStability',
                data: scoreCardOption,
                label: 'Third Party Providers Stability',
            },
            {
                control: control,
                name: 'governanceStability',
                data: scoreCardOption,
                label: 'Governance Stability',
            },
            {
                control: control,
                name: 'bigFourAuditor',
                data: scoreCardOption,
                label: 'Big Four Auditor',
            },
            {
                control: control,
                name: 'esgPolicy',
                data: scoreCardOption,
                label: 'ESG Policy',
            },
            {
                control: control,
                name: 'donations',
                data: scoreCardOption,
                label: 'Donations',
            },
            {
                control: control,
                name: 'unpriSignatory',
                data: scoreCardOption,
                label: 'UNPRI Signatory',
            },
            {
                control: control,
                name: 'independentRisk',
                data: [...scoreCardOption, 'Yes with Investment Team'],
                label: 'Independent Risk',
            },
        ],
        [control, scoreCardOption]
    );
    return (
        <Grid container>
            {textAreaData.map(
                ({control, name, labelName, updateDate, updateName}) => (
                    <Grid
                        container
                        item
                        gap={1}
                        key={name}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CustomTextarea
                            name={name}
                            control={control}
                            labelName={labelName}
                            updateDate={updateDate}
                            updateName={updateName}
                        />
                    </Grid>
                )
            )}

            <Grid container item sx={{width: '100%'}} gap={2}>
                <Grid
                    container
                    item
                    sx={{
                        width: '100%',
                    }}
                >
                    <CheckboxComponent
                        control={control}
                        name='sentToConnect'
                        labelName='Send to connect'
                        checkboxSize={45}
                    />
                </Grid>
            </Grid>

            <Grid container item gap={3}>
                {buttonGroupButtonData.map(({control, name, data, label}) => (
                    <Grid key={name}>
                        <Typography margin={'10px 0'}>{label}</Typography>

                        <Grid item>
                            <ButtonGroup
                                control={control}
                                name={name}
                                data={data}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default ScoreCard;
