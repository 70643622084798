import {Typography} from '@mui/material';
import React from 'react';

interface Props {
    readonly date?: string;
    readonly name?: string;
}

function FooterNote({date = 'none', name = 'none'}: Props) {
    return (
        <Typography>{`Last updated at ${date} perfomed by ${
            name
        }`}</Typography>
    );
}

export default FooterNote;
