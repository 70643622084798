import {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Button, Grid} from '@mui/material';
import dayjs from 'dayjs';
import {GET_GENERATED_REPORTS} from '../../graphql/queries/reports';
import {useQuery} from '@apollo/client';
import Loading from '../loading/loading';
import {useForm} from 'react-hook-form';
import {formats, generateByData, statusData,} from '../../data/shared/data-for-select';
import OptionSelect from '../../components/option-select/option-select';
import {useUIControlContext} from '../../context/ui-control-context';
import {useNavigate} from 'react-router-dom';
import {GeneratedReports} from '../../types/reports';
import {IFormInputs} from '../../types/shared';
import {useDataProcessingContext} from '../../context/data-processing-context';
import {columns} from '../../data/columns/reports-list';
import ErrorPage from '../error/error-page';
import CustomTable from '../../components/custom-table/custom-table';
import DateTimePickerInfo from '../../components/date-time-picker-info/date-time-picker-info';
import TextInput from '../../components/text-input/text-input';

function ReportsList() {
    document.title = `AppHub - Dqr - Generation List`;
    const {handleNavigateToProviderList} = useUIControlContext();
    const {selected, setSelected, transformInUnixValue, handleChipStatus} =
        useDataProcessingContext();
    const navigate = useNavigate();
    const handleNavigate = useCallback(
        (id: string | number, type: string | undefined) => {
            navigate(`/bnp/reports-list/${id}:${type}`);
        },
        [navigate]
    );

    const [generatedReportsData, setGeneratedReportsData] = useState<
        GeneratedReports[]
    >([]);

    const [filteredReportsData, setFilteredReportsData] =
        useState<GeneratedReports[]>(generatedReportsData);

    const {control, watch, reset} = useForm<IFormInputs>({
        defaultValues: {
            type: '',
            status: '',
            format: '',
            searchValue: '',
            dateTimeReportsList: null,
        },
    });

    const typeValue = watch('type');
    const statusValue = watch('status');
    const formatValue = watch('format');
    const searchValue = watch('searchValue');
    const dateTimeReportsList = watch('dateTimeReportsList');

    const {data, loading} = useQuery(GET_GENERATED_REPORTS);

    const formatDate = useCallback((date: string) => {
        return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
    }, []);

    const rows = useMemo(() => {
        return filteredReportsData?.map(
            ({
                 generationId,
                 dateFrom,
                 dateTo,
                 type,
                 generated,
                 status,
                 reportFormat,
             }) => {
                return {
                    id: generationId,
                    type: type,
                    dateFrom: formatDate(dateFrom),
                    dateTo: formatDate(dateTo),
                    generated: formatDate(generated),
                    status: status,
                    format: reportFormat,
                };
            }
        );
    }, [filteredReportsData, formatDate]);

    const filterReports = useCallback(() => {
        if (
            !dateTimeReportsList &&
            !typeValue &&
            !statusValue &&
            !formatValue &&
            !searchValue
        ) {
            setFilteredReportsData(generatedReportsData);
            return;
        }
        const filteredData = generatedReportsData.filter((report) => {
            const dateFrom = dayjs(report.dateFrom);
            const dateTo = dayjs(report.dateTo);
            const generated = dayjs(report.generated);

            const isDateMatching =
                !dateTimeReportsList ||
                dateFrom.isSame(dateTimeReportsList, 'date') ||
                dateTo.isSame(dateTimeReportsList, 'date') ||
                generated.isSame(dateTimeReportsList, 'date');

            const isTypeMatching =
                !typeValue ||
                report.type?.toLowerCase().replace(/\s+/g, '') ===
                typeValue.toLowerCase().replace(/\s+/g, '');

            const isStatusMatching =
                !statusValue ||
                report.status?.toLowerCase().replace(/\s+/g, '') ===
                statusValue.toLowerCase().replace(/\s+/g, '');

            const isFormatMatching =
                !formatValue ||
                report.reportFormat?.toLowerCase().replace(/\s+/g, '') ===
                formatValue.toLowerCase().replace(/\s+/g, '');

            const isSearchMatching =
                !searchValue ||
                report?.generationId
                    ?.toString()
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(searchValue.toLowerCase().replace(/\s+/g, ''));

            return (
                isTypeMatching &&
                isStatusMatching &&
                isFormatMatching &&
                isDateMatching &&
                isSearchMatching
            );
        });

        setFilteredReportsData(filteredData);
    }, [
        dateTimeReportsList,
        formatValue,
        generatedReportsData,
        statusValue,
        typeValue,
        searchValue,
    ]);

    const handleClearFilter = useCallback(() => {
        reset();
    }, [reset]);

    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'type',
                data: generateByData,
                labelId: 'select-type-label',
                label: true,
                selectId: 'select-type',
                labelName: 'Type',
            },
            {
                control: control,
                name: 'status',
                data: statusData,
                labelId: 'select-status-label',
                label: true,
                selectId: 'select-status',
                labelName: 'status',
            },
            {
                control: control,
                name: 'format',
                data: formats,
                labelId: 'select-format-label',
                label: true,
                selectId: 'select-format',
                labelName: 'format',
            },
        ],
        [control]
    );

    const actionsData = useMemo(
        () => [
            {
                status: 'NoResults',
                color: 'warning',
                buttonText: 'send',
                actionType: 'reportListAction',
                actionEvent: (reportListId: string, type?: string) =>
                    handleNavigateToProviderList(reportListId, type as string),
            },
        ],
        [handleNavigateToProviderList]
    );

    useEffect(() => {
        try {
            if (data) {
                const transformedData = data.reportGenerationResult.map(
                    (item: GeneratedReports) => ({
                        ...item,
                        unixValue: transformInUnixValue(item.generated),
                    })
                );

                setGeneratedReportsData(transformedData);
            }
        } catch (error) {
            console.log('error-getting-reports-list', error);
        }
    }, [data, transformInUnixValue]);

    useEffect(() => {
        filterReports();
    }, [
        dateTimeReportsList,
        typeValue,
        statusValue,
        formatValue,
        filterReports,
    ]);

    if (loading) return <Loading/>;

    return (
        <Box component={'article'} sx={{width: '100%'}}>
            <Grid container gap={2} flexDirection='column'>
                {generatedReportsData.length > 0 ? (
                    <>
                        <Grid item container gap={2} alignItems='center'>
                            {selectData?.map(
                                ({
                                     control,
                                     name,
                                     data,
                                     labelId,
                                     label,
                                     selectId,
                                     labelName,
                                 }) => (
                                    <Grid
                                        item
                                        sx={{width: '150px'}}
                                        key={name}
                                    >
                                        <OptionSelect
                                            control={control}
                                            name={name}
                                            data={data}
                                            labelId={labelId}
                                            label={label}
                                            selectId={selectId}
                                            labelName={labelName}
                                        />
                                    </Grid>
                                )
                            )}

                            <Grid item>
                                <DateTimePickerInfo
                                    labelName='Select date'
                                    name='dateTimeReportsList'
                                    control={control}
                                />
                            </Grid>

                            <Grid item>
                                <TextInput
                                    name='searchValue'
                                    control={control}
                                    labelName={'Search by id'}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    size='large'
                                    color='warning'
                                    onClick={handleClearFilter}
                                    variant='contained'
                                >
                                    Clear filters
                                </Button>
                            </Grid>

                            {filteredReportsData &&
                                filteredReportsData.length > 0 && (
                                    <Grid item sx={{width: '100%'}}>
                                        <CustomTable
                                            useCheckbox={false}
                                            selected={selected}
                                            setSelected={setSelected}
                                            columns={columns}
                                            rows={rows}
                                            handleNavigate={handleNavigate}
                                            handleChipStatus={handleChipStatus}
                                            transformInUnixValue={
                                                transformInUnixValue
                                            }
                                            actionsData={actionsData}
                                        />
                                    </Grid>
                                )}
                        </Grid>

                        {filteredReportsData?.length === 0 && (
                            <ErrorPage
                                errorMessage={'There are no reports to show'}
                            />
                        )}
                    </>
                ) : (
                    <ErrorPage errorMessage={'There are no reports to show'}/>
                )}
            </Grid>
        </Box>
    );
}

export default ReportsList;
