import {TextField, Tooltip} from '@mui/material';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Data} from '../../types/shared';

type InputFormat =
    | 'moneyMarket'
    | 'fixedIncome'
    | 'balance'
    | 'alternatives'
    | 'equity'
    | 'fundOfFunds'
    | 'othersProductSegmentation'
    | 'totalProductSegmentation'
    | 'retail'
    | 'othersClientSegmentation'
    | 'captive'
    | 'totalClientSegmentation'
    | 'institutional'
    | 'yOne'
    | 'yTwo'
    | 'yThree'
    | 'yFour'
    | 'yFive'
    | 'reportId'
    | 'searchValue'
    | 'fundIsin';

function TextInput({
                       name,
                       control,
                       labelName,
                       ruleRequired,
                       placeholder,
                       inputComponent,
                       disabledInput,
                       inputType,
                       minLengthError,
                       minLengthErrorValue,
                   }: Readonly<Data>) {
    return (
        <Controller
            name={name as InputFormat}
            control={control}
            rules={{
                required: ruleRequired ? 'This field is required' : false,
                minLength: {
                    value: minLengthErrorValue as number,
                    message: minLengthError as string,
                },
            }}
            render={({field, fieldState}) => (
                <Tooltip title={field.value} placement='bottom'>
                    <TextField
                        type={inputType}
                        {...field}
                        error={!!fieldState.error}
                        helperText={
                            fieldState.error ? fieldState.error.message : null
                        }
                        placeholder={placeholder}
                        label={labelName}
                        fullWidth
                        InputProps={{
                            inputComponent: inputComponent,
                        }}
                        disabled={name === disabledInput}
                    />
                </Tooltip>
            )}
        />
    );
}

export default TextInput;
