import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {IFormInputs, Translation} from '../../../types/shared';
import GeneralInformation from './general-information/general-information';
import Team from './team/team';
import Assessment from './assessment/assessment';
import InvestmentProcess from './investment-process/investment-process';
import {Box} from '@mui/system';
import TabComponent from '../../../components/tab/tab';
import {CircularProgress, Divider, Grid} from '@mui/material';
import {dataForSelect} from '../../../data/research-list/general-information/data-for-select';
import axios, {AxiosError} from 'axios';
import {Benchmark, ISIN} from '../../../types/research-detail';
import {AfbStatus, EsgTypes, Fund, Isin, PeerGroup} from '../../../types/research-list';
import {useUIControlContext} from '../../../context/ui-control-context';
import Loading from '../../loading/loading';
import {useSharedDataContext} from '../../../context/shared-data-context';
import OptionSelect from '../../../components/option-select/option-select';
import {useNavigate, useParams} from 'react-router-dom';
import {useStore} from '../../../store';
import {ManagerData} from '../../../types/risk-detail';
import Toast from '../../../components/toast/toast';
import SimpleBackdrop from '../../../components/backdrop/backdrop';
import CustomButton from '../../../components/custom-button/custom-button';
import Esg from './esg/esg';
import Isins from './isins/isins';
import TextInput from '../../../components/text-input/text-input';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';
import {useDataProcessingContext} from '../../../context/data-processing-context';
import {flattenEsgData} from "./esg/mapping";

function ResearchDetail() {
    const {researchId} = useParams();
    const [accessToken] = useStore((state) => [state.accessToken]);
    const {selected, setSelected} = useDataProcessingContext();
    const {
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        openBackdrop,
        handleOpenBackdrop,
        handleManageToast,
        handleCloseBackdrop,
        handleCloseDialog,
        loading,
        setLoading,
        handleLogOut,
        isDialogOpen,
        handleOpenDialog,
        refetchData,
        setRefetchData,
    } = useUIControlContext();
    const [benchmarkData, setBenchmarkData] = useState<Benchmark[]>([]);
    const [researchData, setResearchData] = useState<Fund>();
    const [esgData, setEsgData] = useState<EsgTypes>();
    const [esgDataId, setEsgDataId] = useState<string>();
    const [managerData, setManagerData] = useState<ManagerData[]>([]);
    const [managerDataNames, setManagerDataNames] = useState<string[]>([]);
    const [managerMap, setManagerMap] = useState<Record<string, string>>({});
    const [isinsData, setIsinsData] = useState<ISIN[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isDialogOpenUnderReview, setIsDialogOpenUnderReview] = useState(false);
    const [underReviewOld, setUnderReviewOld] = useState(false);
    const [afbStatusOld, setAfbStatusOld] = useState<number>(0);
    const {
        peerGroupData,
        peerGroupNames,
        handleTransformBooleanValue,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
        handleGetPeerGroup,
        handleGetAfbStatusGroup,
        afbStatusData,
        afbStatusNames,
        handleTransformToBooleanValue,
        baseUrl,
        newId,
        handleGetLanguages,
        languagesData,
    } = useSharedDataContext();

    const [tab, setTab] = useState('GENERAL INFORMATION');
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: {
            languages: 'English',
            fundIsin: '',
            fundFamilyName: '',
            belongsToInsightList: '',
            applyAllFamilyFunds: true,
            factsheetDate: dayjs(),
            manager: '',
            peerGroupGeneralInfo: '',
            benchmark: '',
            afbStatusGeneralInfo: '',
            underReview: false,
            fundDescription: '',
            teamStars: 3,
            team: '',
            investmentProcessStars: 2,
            investmentProcess: '',
            assessment: '',
            searchValue: '',
            comment: '',
            file: [],
            esgData: ''
        },
    });
    const languagesValue = watch('languages');
    const searchValue = watch('searchValue');
    const fundDescriptionLastUpdate = watch('fundDescriptionLastUpdate');
    const fundDescriptionLastUpdateName = watch(
        'fundDescriptionLastUpdateName'
    );
    const teamLastUpdate = watch('teamLastUpdate');
    const teamLastUpdateName = watch('teamLastUpdateName');

    const investmentProcessLastUpdate = watch('investmentProcessLastUpdate');
    const investmentProcessUpdateName = watch('investmentProcessUpdateName');

    const assessmentLastUpdate = watch('assessmentLastUpdate');
    const assessmentLastUpdateName = watch('assessmentLastUpdateName');

    const peerGroupValue = watch('peerGroupGeneralInfo');
    const underReview = watch('underReview');
    const afbStatus = watch('afbStatusGeneralInfo');

    const handleOpenDialogConditional = () => {
        const afbstatus_id = handleGetIdAfbStatus(afbStatusData, afbStatus as string);
        const normalizedAfbstatusId = afbstatus_id ?? 0;
        const normalizedAfbStatusOld = afbStatusOld ?? 0;
        console.log(underReview + '' + underReviewOld);
        console.log(normalizedAfbstatusId + '' + normalizedAfbStatusOld);
        if (underReview != underReviewOld || normalizedAfbstatusId !== normalizedAfbStatusOld) {
            setIsDialogOpenUnderReview(true);
        } else {
            handleSubmit(onSubmit)();
        }
    };

    const handleCloseDialogUnderReview = () => {
        setIsDialogOpenUnderReview(false);
    };

    const handleChangeTab = useCallback(
        (event: React.SyntheticEvent, newValue: string) => {
            setTab(newValue);
        },
        [setTab]
    );

    const fetchResearchData = useCallback(
        async (id: string) => {
            setLoading(true);
            try {

                const [researchResponse, esgResponse] = await Promise.all([
                    axios.get(`${baseUrl}Fund/${researchId}`, {headers: {Authorization: `Bearer ${accessToken}`}}),
                    axios.get(`${baseUrl}Fund/${researchId}/esg`, {headers: {Authorization: `Bearer ${accessToken}`}})
                ]);
                const fundModification = {
                    ...researchResponse?.data?.data?.fundModification,
                    manager_Id: researchResponse?.data?.data?.fund?.managerId,
                };
                setResearchData(fundModification);
                setEsgData(esgResponse?.data?.data?.fundModification);
                setEsgDataId(esgResponse?.data?.data?.fundModification.id);
                setUnderReviewOld(researchResponse?.data.data.fundModification?.afbStatus_underReview?.value);
                setAfbStatusOld(researchResponse?.data.data.fundModification?.status_Id?.value);
                const isinsList = researchResponse?.data?.data?.fundModification?.isins;
                setIsinsData(isinsList.filter((Isin: Isin) => Isin.closedDate === null));
            } catch (error) {
                console.log(error)
                const typedError = error as { response?: { status?: number } };
                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 400) {
                    handleManageToast('error', 'Check the different fields.');
                } else {
                    handleManageToast(
                        'error',
                        'Error saving data. Please try again.'
                    );
                }
            } finally {
                setLoading(false);
            }
        },
        [
            setLoading,
            baseUrl,
            accessToken,
            setResearchData,
            handleLogOut,
            setIsinsData,
            setEsgData
        ]
    );

    const handleCreateISIN: SubmitHandler<IFormInputs> = useCallback(
        async ({fundIsin}) => {
            setLoading(true);
            try {
                const dataCreateIsin = {
                    ISIN: fundIsin,
                    Fund_Id: researchId,
                };

                const response = await axios.post(
                    `${baseUrl}Fund/${researchId}/Isin`,
                    dataCreateIsin,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response) {
                    handleManageToast('success', 'ISIN created successfully.');
                }

                setRefetchData((prev) => !prev);
                handleCloseDialog();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 400) {
                    handleManageToast('error', 'ISIN already exists.');
                } else {
                    handleManageToast(
                        'error',
                        'Error creating ISIN. Please try again.'
                    );
                }
            } finally {
                reset();
            }
        },
        [
            reset,
            setRefetchData,
            accessToken,
            setLoading,
            handleLogOut,
            handleCloseDialog,
            handleManageToast,
            baseUrl,
        ]
    );

    const fetchManagerData = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}Manager`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response) {
                const normalizedData = [
                    {id: "", name: "--- Empty ---"},
                    ...response.data.data,
                ];
                setManagerData(normalizedData);

                const managerNames = normalizedData.map((manager) => manager.name); // Extraemos solo los nombres
                setManagerDataNames(managerNames);

                const map = normalizedData.reduce((acc: Record<string, string>, manager: ManagerData) => {
                    acc[manager.name] = manager.id;
                    return acc;
                }, {});
                setManagerMap(map);
            }
        } catch (error) {
            const typedError = error as { response?: { status?: number } };
            if (typedError.response?.status === 401) {
                handleLogOut();
            }
        }
    }, [baseUrl, accessToken, handleLogOut]);

    const handleGetManagerName = useCallback(
        (managerId: string | null) => {
            if (managerData) {
                return managerData.find((manager) => manager?.id === managerId)
                    ?.name;
            }
        },
        [managerData]
    );

    const handleGetPeerGroupName = useCallback(
        (peerGroupId: number) => {
            if (peerGroupData) {
                return peerGroupData.find((peer) => peer?.id === peerGroupId)
                    ?.name;
            }
        },
        [peerGroupData]
    );

    const handleGetAfbStatusName = useCallback(
        (afbStatusId: number) => {
            if (afbStatusData) {
                return afbStatusData.find(
                    (status) => status?.id === afbStatusId
                )?.name;
            }
        },
        [afbStatusData]
    );

    interface ErrorMessage {
        message: string;
        type: string;
    }

    interface ErrorData {
        messages: ErrorMessage[];
    }

    const handleDeleteIsin = useCallback(
        async (id: string, fundId: string) => {
            if (id && selected.length === 1) {
                const apiUrl = `${baseUrl}Fund/Isin/${id}`;
                setLoading(true);
                try {
                    const response = await axios.delete(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    if (response) {
                        fetchResearchData(fundId);
                        handleManageToast(
                            'success',
                            ' Isin deleted succesfully'
                        );
                    }
                    handleCloseDialog();
                } catch (error) {
                    const axiosError = error as AxiosError<ErrorData>;
                    if (axiosError.response) {
                        const statusCode = axiosError.response.status;
                        switch (statusCode) {
                            case 401: {
                                handleManageToast('error', 'You are not logged, try to log in again.');
                                handleLogOut();
                                break;
                            }
                            case 500: {
                                handleManageToast('error', 'Server error, please try again later.');
                                break;
                            }
                            default: {
                                const messages = axiosError.response.data?.messages;
                                if (messages && messages.length > 0) {
                                    handleManageToast('error', messages[0].message);
                                } else {
                                    handleManageToast('error', 'There was an error deleting the Isin');
                                }
                                break;
                            }
                        }
                    } else {
                        handleManageToast('error', 'There was an error deleting the Isin');
                    }
                } finally {
                    setLoading(false);
                    handleCloseDialog();
                }
            } else {
                handleManageToast(
                    'error',
                    'You can only erase one isin, please select one'
                );
            }
        },
        [
            setRefetchData,
            selected,
            baseUrl,
            handleCloseDialog,
            handleManageToast,
            accessToken,
            handleLogOut,
        ]
    );

    const navigate = useNavigate();

    const handleNavigateToReviews = useCallback(
        (
            fundId: string
        ) => {
            navigate(`/dqr/research-list/${fundId}/reviews`);
        },
        [navigate]
    );

    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'manager',
                data: managerDataNames,
                labelId: 'select-manager-label',
                label: true,
                selectId: 'select-manager',
                labelName: 'Manager',
            },
            {
                control: control,
                name: 'peerGroupGeneralInfo',
                data: peerGroupNames,
                labelId: 'select-peerGroupGeneralInfo-label',
                label: true,
                selectId: 'select-peer-group-general-info',
                labelName: 'Peer Group',
            },
            {
                control: control,
                name: 'afbStatusGeneralInfo',
                data: [{id: '', name: '--- Empty ---'}, ...afbStatusNames],
                labelId: 'select-afb-status-general-info-label',
                label: true,
                selectId: 'select-afbstatus',
                labelName: 'Afb Status',
            },
        ],
        [
            control,
            peerGroupNames,
            dataForSelect,
            afbStatusNames,
            managerDataNames,
        ]
    );

    const textInputData = useMemo(
        () => [
            {
                control: control,
                name: 'fundFamilyName',
                labelName: 'Fund Family Name',
            },
            {
                control: control,
                name: 'benchmark',
                labelName: 'Benchmark',
            },
        ],
        [control]
    );

    const datePickerData = useMemo(
        () => [
            {
                control: control,
                name: 'factsheetDate',
                labelName: 'Factsheet Date',
            },
        ],
        [control]
    );

    const toggleData = useMemo(
        () => [
            {
                control: control,
                name: 'underReview',
                labelName: 'Under Review',
            },
        ],
        [control]
    );

    const textareaData = useMemo(
        () => [
            {
                control: control,
                name: 'fundDescription',
                labelName: 'Fund Description',
                updateName: fundDescriptionLastUpdateName,
                updateDate: fundDescriptionLastUpdate,
            },
        ],
        [control, fundDescriptionLastUpdateName, fundDescriptionLastUpdate]
    );

    const handleChangeSectionByTab = useCallback(() => {
        if (tab === 'GENERAL INFORMATION') {
            return (
                <GeneralInformation
                    control={control}
                    selectData={selectData}
                    textInputData={textInputData}
                    datePickerData={datePickerData}
                    toggleData={toggleData}
                    textareaData={textareaData}
                    researchId={researchId}
                />
            );
        } else if (tab === 'TEAM') {
            return (
                <Team
                    control={control}
                    teamLastUpdate={teamLastUpdate}
                    teamLastUpdateName={teamLastUpdateName}
                />
            );
        } else if (tab === 'INVESTMENT PROCESS') {
            return (
                <InvestmentProcess
                    control={control}
                    investmentProcessUpdateName={investmentProcessUpdateName}
                    investmentProcessLastUpdate={investmentProcessLastUpdate}
                />
            );
        } else if (tab === 'ASSESSMENT') {
            return (
                <Assessment
                    control={control}
                    assessmentLastUpdateName={assessmentLastUpdateName}
                    assessmentLastUpdate={assessmentLastUpdate}
                />
            );
        } else if (tab === 'EXCLUSIONS') {
            return (
                <Esg
                    control={control}
                    esgData={esgData}
                />
            );
        } else if (tab === 'ISINS') {
            return (
                <Isins
                    handleDeleteIsin={handleDeleteIsin}
                    selected={selected}
                    setSelected={setSelected}
                    handleOpenDialog={handleOpenDialog}
                    data={isinsData}
                    searchValue={searchValue}
                    control={control}
                />
            );
        }
    }, [
        handleDeleteIsin,
        selected,
        setSelected,
        searchValue,
        tab,
        handleOpenDialog,
        control,
        selectData,
        textInputData,
        datePickerData,
        toggleData,
        textareaData,
        teamLastUpdate,
        teamLastUpdateName,
        investmentProcessLastUpdate,
        investmentProcessUpdateName,
        assessmentLastUpdate,
        assessmentLastUpdateName,
        isinsData,
        esgData
    ]);

    const tabsArray = [
        {
            tabValue: 'GENERAL INFORMATION',
            tabLabel: 'General information',
        },
        {
            tabValue: 'TEAM',
            tabLabel: 'Team',
        },
        {
            tabValue: 'INVESTMENT PROCESS',
            tabLabel: 'Investment process',
        },
        {
            tabValue: 'ASSESSMENT',
            tabLabel: 'Assessment',
        },
        {
            tabValue: 'EXCLUSIONS',
            tabLabel: 'EXCLUSIONS',
        },
        {
            tabValue: 'ISINS',
            tabLabel: 'ISINS',
        },
    ];

    const handleGetIdPeerGroup = useCallback(
        (data: PeerGroup[] | null, name: string): number | undefined => {
            if (!data) return undefined;

            return data.find((item) => item.name === name)?.id as number;
        },
        []
    );
    const handleGetIdAfbStatus = useCallback(
        (data: AfbStatus[] | null, name: string): number | undefined => {
            if (!data) return undefined;

            const item = data.find((item) => item.name === name);

            return item?.id as number;
        },
        []
    );

    const onSubmit: SubmitHandler<IFormInputs> = useCallback(
        async ({
                   fundFamilyName,
                   belongsToInsightList,
                   applyAllFamilyFunds,
                   factsheetDate,
                   manager,
                   peerGroupGeneralInfo,
                   afbStatusGeneralInfo,
                   underReview,
                   fundDescription,
                   teamStars,
                   team,
                   investmentProcessStars,
                   investmentProcess,
                   assessment,
                   comment,
                   file,
                   esgData
               }) => {
            const apiUrlFund = `${baseUrl}Fund/${researchId}`;
            const apiUrlFundEsg = `${baseUrl}Fund/${researchId}/esg`;
            const apiUrlTranslations = `${baseUrl}Fund/${researchId}/Translation`;
            const apiUrlFundUnderReviewDocument = `${baseUrl}Fund/${researchId}/UploadFile`;
            const formData = new FormData();
            let documentId = undefined;
            if (file && file.length > 0) {
                formData.append("file", file[0]);
            }
            setIsDialogOpenUnderReview(false);
            handleOpenBackdrop();
            if (file?.length > 0) {
                try {
                    const response = await axios.post(apiUrlFundUnderReviewDocument, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${accessToken}`,
                        }
                    });
                    console.log('Success:', response.data);
                    console.log('Id:', response.data.data.id);
                    documentId = response.data.data.id;
                } catch (error) {
                    handleManageToast(
                        'error',
                        'Error with document'
                    );
                    console.error('Error submitting form:', error);
                    handleCloseBackdrop();
                    return;
                }
            }
            setSubmitting(true);
            const currentManager = watch('manager');
            const currentManagerId = currentManager && managerMap[currentManager] ? managerMap[currentManager] : null;
            const responses = [];
            try {
                const dataFund = {
                    id: researchId,
                    name: fundFamilyName,
                    isInsight: handleTransformToBooleanValue(
                        belongsToInsightList as string
                    ),
                    applyFamily: applyAllFamilyFunds,
                    factSheetDate: factsheetDate,
                    startDate: dayjs(),
                    afbStatus_underReview: underReview,
                    teamRating: teamStars,
                    investmentProcessRating: investmentProcessStars,
                    manager_Id: currentManagerId,
                    peerGroup_Id: handleGetIdPeerGroup(
                        peerGroupData,
                        peerGroupGeneralInfo as string
                    ),
                    status_Id: handleGetIdAfbStatus(
                        afbStatusData,
                        afbStatusGeneralInfo as string
                    ),
                    reviewComment: comment,
                    fundUploadFile_Id: documentId
                };

                const languageId = handleGetLanguageIdFromSelect(
                    languagesValue as string
                );

                const languageItem = handleGetLanguageItem(
                    researchData?.translations as Translation[],
                    languageId as number
                );

                const dataTranslations = {
                    id: languageItem ? languageItem['id'] : newId,
                    fund_Id: researchId,
                    language_Id: languageId,
                    profile: 'string',
                    afbStatus: 'string',
                    fundDescription: fundDescription,
                    fundSources: assessment,
                    teamDescription: team,
                    investmentProcessDescription: investmentProcess,
                };

                esgData = flattenEsgData(esgData);

                const esgDataWithIDs = {
                    ...esgData,
                    id: esgDataId,
                    fundId: researchId
                };

                const results = await Promise.allSettled([
                    axios.put(apiUrlFund, dataFund, {headers: {Authorization: `Bearer ${accessToken}`}}),
                    axios.put(apiUrlFundEsg, esgDataWithIDs, {headers: {Authorization: `Bearer ${accessToken}`}}),
                    axios.post(apiUrlTranslations, dataTranslations, {headers: {Authorization: `Bearer ${accessToken}`}})
                ]);

                let conflictCount = 0;

                results.forEach(result => {
                    if (result.status === 'rejected' && result.reason?.response?.status === 409) {
                        conflictCount++;
                    } else if (result.status === 'rejected' && result.reason?.response?.status === 401) {
                        handleLogOut();
                        return;
                    } else if (result.status === 'rejected') {
                        handleManageToast('error', 'There was an unexpected error trying to save the data');
                    }
                });
                if (conflictCount === results.length) {
                    handleManageToast('success', 'No changes were made, data already up-to-date');
                } else if (conflictCount < results.length) {
                    handleManageToast('success', 'Data saved successfully');
                }

            } catch (error) {
                handleManageToast('error', 'An unexpected error occurred.');
            } finally {
                handleCloseBackdrop();
                setSubmitting(false);
                setRefetchData(prev => !prev);
            }
        },
        [
            researchData,
            researchId,
            baseUrl,
            handleCloseBackdrop,
            handleOpenBackdrop,
            handleManageToast,
            handleTransformToBooleanValue,
            handleGetIdPeerGroup,
            handleGetIdAfbStatus,
            languagesValue,
            accessToken,
            setSubmitting,
            handleGetLanguageIdFromSelect,
            handleGetLanguageItem,
            handleLogOut,
            newId,
            managerMap
        ]
    );

    const handleGetBenchmark = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}Benchmark`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            setBenchmarkData(response.data.data);
        } catch (error) {
            console.error('Error fetching benchmark data:', error);
        }
    }, [baseUrl, accessToken]);

    const handleBenchmarkByPeerGroup = useCallback(() => {
        if (peerGroupValue) {
            const corretPeerGroup = peerGroupData.find(
                (data) => data.name === peerGroupValue
            );

            const correctBenchmark = benchmarkData.find(
                (benchmark) => benchmark.id === corretPeerGroup?.benchmark_Id
            );
            if (correctBenchmark) {
                setValue('benchmark', correctBenchmark.name);
            }
        }
    }, [peerGroupValue, peerGroupData, benchmarkData, setValue]);

    useEffect(() => {
        handleGetPeerGroup();
        handleGetAfbStatusGroup();
        handleGetBenchmark();
        handleGetLanguages();
    }, [
        handleGetPeerGroup,
        handleGetAfbStatusGroup,
        handleGetBenchmark,
        handleGetLanguages,
    ]);

    useEffect(() => {
        handleBenchmarkByPeerGroup();
    }, [handleBenchmarkByPeerGroup]);

    useEffect(() => {
        fetchResearchData(researchId as string);
    }, [researchId, refetchData]);
    useEffect(() => {
        fetchManagerData();
    }, [fetchManagerData]);

    useEffect(() => {
        if (researchData) {
            setValue('underReview', researchData?.afbStatus_underReview?.value);
            setValue('applyAllFamilyFunds', researchData?.applyFamily?.value);
            setValue(
                'factsheetDate',
                dayjs(researchData?.factSheetDate?.value)
            );
            setValue(
                'belongsToInsightList',
                handleTransformBooleanValue(researchData?.isInsight?.value)
            );
            setValue(
                'peerGroupGeneralInfo',
                handleGetPeerGroupName(researchData?.peerGroup_Id?.value)
            );
            setValue('fundFamilyName', researchData?.name);
            setValue(
                'afbStatusGeneralInfo',
                handleGetAfbStatusName(researchData?.status_Id?.value)
            );
            setValue('teamStars', researchData?.teamRating?.value);
            setValue(
                'investmentProcessStars',
                researchData?.investmentProcessRating?.value
            );
            setValue(
                'manager',
                handleGetManagerName(researchData?.manager_Id)
            );

            setValue('comment', researchData?.reviewComment);

            if (researchData?.translations) {
                const languageId = handleGetLanguageIdFromSelect(
                    languagesValue as string
                );
                const languageItem = handleGetLanguageItem(
                    researchData.translations as Translation[],
                    languageId as number
                );

                if (languageItem) {
                    setValue(
                        'fundDescription',
                        languageItem?.fundDescription?.value
                    );

                    setValue(
                        'fundDescriptionLastUpdate',
                        dayjs(
                            languageItem?.fundDescription?.lastModification
                                ?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'fundDescriptionLastUpdateName',
                        languageItem?.fundDescription?.lastModification
                            ?.lastModifiedBy
                    );

                    setValue('team', languageItem?.teamDescription?.value);

                    setValue(
                        'teamLastUpdate',
                        dayjs(
                            languageItem?.teamDescription?.lastModification
                                ?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'teamLastUpdateName',
                        languageItem?.teamDescription?.lastModification
                            ?.lastModifiedBy
                    );

                    setValue('assessment', languageItem?.fundSources?.value);

                    setValue(
                        'assessmentLastUpdate',
                        dayjs(
                            languageItem?.fundSources?.lastModification
                                ?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'assessmentLastUpdateName',
                        languageItem?.fundSources?.lastModification
                            ?.lastModifiedBy
                    );

                    setValue(
                        'investmentProcess',
                        languageItem?.investmentProcessDescription?.value
                    );

                    setValue(
                        'investmentProcessLastUpdate',
                        dayjs(
                            languageItem?.investmentProcessDescription
                                ?.lastModification?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'investmentProcessUpdateName',
                        languageItem?.investmentProcessDescription
                            ?.lastModification?.lastModifiedBy
                    );
                }
            }
        }
    }, [
        setValue,
        researchData,
        handleTransformBooleanValue,
        handleGetPeerGroupName,
        handleGetAfbStatusName,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
        languagesValue,
        handleGetManagerName,
    ]);

    if (loading && !isDialogOpen) return <Loading/>;

    document.title = `AppHub - Dqr - Research Detail`;
    return (
        <>
            <Box
                onSubmit={handleSubmit(onSubmit)}
                component={isDialogOpen ? 'section' : 'form'}
                width={'100%'}
            >
                <Grid container alignItems={'flex-start'} gap={3}>
                    <TabComponent
                        tab={tab}
                        handleChangeTab={handleChangeTab}
                        tabsArray={tabsArray}
                    />

                    <Grid item sx={{width: '200px'}}>
                        <OptionSelect
                            control={control}
                            data={languagesData}
                            name='languages'
                            labelName='Languages'
                            label
                        />
                    </Grid>
                </Grid>

                <Grid container flexDirection={'column'} gap={3}>
                    <Grid item>{handleChangeSectionByTab()}</Grid>
                </Grid>

                <Divider/>
                <Grid
                    container
                    item
                    justifyContent={'flex-end'}
                    sx={{margin: '17px 0'}}
                    gap={2}
                >
                    <CustomButton
                        onClick={handleOpenDialogConditional}
                        color='success'
                        variant='contained'
                        size='large'
                        disabled={submitting}
                        text={'Save'}
                    />
                </Grid>
                <Toast
                    handleCloseToast={handleCloseToast as () => void}
                    openToast={openToast as boolean}
                    severity={alertSeverity}
                    message={alertMessage}
                />

                <SimpleBackdrop
                    openBackdrop={openBackdrop as boolean}
                />
            </Box>

            <CustomDialog
                submitForm={handleSubmit(handleCreateISIN)}
                isAForm
                open={isDialogOpen as boolean}
                title='New ISIN'
                text=''
                onClose={handleCloseDialog}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialog}
                                text='CANCEL'
                            />
                            <CustomButton text='CREATE' type='submit'/>
                        </>
                    )
                }
            >
                <Grid
                    container
                    flexDirection={'column'}
                    gap={2}
                    marginTop={'10px'}
                >
                    {loading ? (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <Grid item>
                            <TextInput
                                error={!!errors.fundIsin}
                                ruleRequired
                                name='fundIsin'
                                control={control}
                                labelName='ISIN'
                            />
                        </Grid>
                    )}
                </Grid>
            </CustomDialog>

            <CustomDialog
                open={isDialogOpenUnderReview}
                title='Comment'
                text=''
                onClose={handleCloseDialogUnderReview}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialogUnderReview}
                                text='CANCEL'
                            />
                            <CustomButton text='SAVE' type='button' onClick={handleSubmit(onSubmit)}/>
                        </>
                    )
                }
            >
                <Grid
                    container
                    flexDirection={'column'}
                    gap={2}
                    marginTop={'10px'}
                >
                    {loading ? (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <Grid item>
                            <TextInput
                                ruleRequired
                                name='comment'
                                control={control}
                                labelName='Comment'
                            />
                            <Controller
                                name="file"
                                control={control}
                                defaultValue={[]}
                                render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
                                    <input
                                        type="file"
                                        onChange={e => onChange(e.target.files)}
                                        onBlur={onBlur}
                                        style={{marginTop: '16px'}}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            </CustomDialog>
        </>
    );
}

export default ResearchDetail;
