import React from 'react';
import {Control} from 'react-hook-form';
import {IFormInputs} from '../../../../types/shared';
import {Grid, IconButton, Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import OptionSelect from "../../../../components/option-select/option-select";
import criteria from './criteria';
import FooterNote from "../../../../components/footer-note/footer-note";
import {EsgTypes} from '../../../../types/research-list';
import dayjs from "dayjs";

interface Props {
    readonly control: Control<IFormInputs>;
    readonly esgData?: EsgTypes;
}

const defaultEsgData: EsgTypes = {
    id: "",
    fundId: "",
    adultEntertainment: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    alcohol: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    antiPersonnelMines: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    biologicalWeapons: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    chemicalWeapons: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    civilianFirearms: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    clusterMunitions: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    controversialCountries: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    conventionalWeapons: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    cropProtectionChemicals: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    depletedUraniumWeapons: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    gambling: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    gmo: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    nuclearEnergy: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    nuclearWeapons: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    oilGasConventional: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    oilGasUnconventional: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    thermalCoal: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    tobacco: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
    violationsUnGlobalCompact: {value: 0, lastModification: {lastModifiedDate: new Date().toISOString(), lastModifiedBy: ""}},
};

const options = ["Empty", "Yes", "No", "NA"];

function Esg({control, esgData = defaultEsgData}: Props) {
    console.log(esgData)
    return (
        <Grid container flexDirection={'column'} gap={2} marginTop={'10px'} marginBottom={'20px'}>
            {criteria.map((item, index) => (
                <Grid item key={index} container alignItems="center" spacing={2}>
                    <Grid item xs>
                        {item.exclusion}

                        <Tooltip title={item.definition} arrow>
                            <IconButton>
                                <InfoIcon/>
                            </IconButton>
                        </Tooltip>
                        <OptionSelect
                            control={control}
                            name={`esgData.${item.key}.value`}
                            data={options}
                            label={true}
                            labelId={`${item.exclusion}-label`}
                            selectId={`${item.exclusion}-select`}
                            required={true}
                            defaultValue={options[esgData[item.key as keyof EsgTypes].value] || "Empty"}
                        />
                        <FooterNote
                            date={dayjs(esgData[item.key as keyof EsgTypes]?.lastModification.lastModifiedDate).format('DD/MM/YYYY') || ""}
                            name={esgData[item.key as keyof EsgTypes]?.lastModification.lastModifiedBy || ""}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default Esg;
