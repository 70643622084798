import {Rating} from '@mui/material';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Data} from '../../types/shared';

function StarsRating({name, control}: Readonly<Data>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({field}) => {
                return (
                    <Rating
                        {...field}
                        value={field.value ? parseFloat(field.value) : 0}
                    />
                );
            }}
        />
    );
}

export default StarsRating;
