import {useCallback, useEffect, useMemo, useState} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import {Box, Tab, Tabs} from '@mui/material';
import {config} from '../../config';
import './power-bi.css';
import axios from 'axios';
import {useUIControlContext} from '../../context/ui-control-context';
import Loading from '../loading/loading';
import ErrorPage from '../error/error-page';
import ResponsiveDialog from '../../components/responsive-dialog/responsive-dialog';
import {
    IDashboardEmbedConfiguration,
    IPaginatedReportLoadConfiguration,
    IQnaEmbedConfiguration,
    IReportCreateConfiguration,
    IReportEmbedConfiguration,
    ITileEmbedConfiguration,
    IVisualEmbedConfiguration,
} from 'powerbi-models';

interface Props {
    readonly accessToken: string | null;
    readonly azureToken: string | null;
    readonly setAzureToken: (t: string | null) => void;
}

interface FetchResponse {
    embedUrl?: string;
    token?: string;
}

interface CustomError extends Error {
    response?: {
        status?: number;
    };
}

type PowerBiConfig =
    | IReportEmbedConfiguration
    | IDashboardEmbedConfiguration
    | ITileEmbedConfiguration
    | IQnaEmbedConfiguration
    | IVisualEmbedConfiguration
    | IPaginatedReportLoadConfiguration
    | IReportCreateConfiguration;

export function PowerBi({azureToken, setAzureToken, accessToken}: Props) {
    const {
        alertMessage,
        setAlertMessage,
        handleLogOut,
        handleOpenDialog,
        handleCloseDialog,
        isDialogOpen,
    } = useUIControlContext();

    const [activeTab, setActiveTab] = useState(0);
    const [dialogTitle, setDialogTitle] = useState<string | undefined>(
        undefined
    );
    const [isLoading, setIsLoading] = useState(true);
    const [authTokens, setAuthTokens] = useState<(string | null)[]>([]);
    const [embeddedUrls, setEmbeddedUrls] = useState<(string | null)[]>([]);

    const reportIds = config.reportIds;

    const handleTabChange = useCallback(
        (event: React.ChangeEvent<object>, newValue: number) => {
            setActiveTab(newValue);
        },
        [setActiveTab]
    );

    const renderPowerBiEmbed = useCallback(
        (config: PowerBiConfig) => {
            if (!config.embedUrl || !config.accessToken) {
                return (
                    <ErrorPage errorMessage='The report could not be loaded'/>
                );
            }
            return (
                <PowerBIEmbed
                    embedConfig={config}
                    cssClassName={'reportClass'}
                />
            );
        },
        [config]
    );

    const handleRefreshAzureToken = useCallback(
        async (token: string | null) => {
            const baseUrl = process.env.REACT_APP_AFB_IDENTITY_API;

            try {
                setDialogTitle('Refresh power bi token');
                setAlertMessage('Wait while we refresh your token');
                handleOpenDialog();
                const response = await axios.get(
                    `${baseUrl}${config.refreshPath}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response) {
                    const {token} = response.data.data;
                    setAzureToken(token);
                }

                handleCloseDialog();
            } catch (error: unknown) {
                const customError = error as CustomError;

                if (customError.response?.status === 401) {
                    handleLogOut();
                } else {
                    setDialogTitle('Refresh power bi token');
                    setAlertMessage(
                        'There was an unknown error while trying to refresh your token, please contact with allfunds support'
                    );
                    handleOpenDialog();
                }
            }
        },
        [
            setAzureToken,
            setDialogTitle,
            setAlertMessage,
            handleOpenDialog,
            handleCloseDialog,
            handleLogOut,
        ]
    );

    const fetchData = useCallback(
        async (url?: string, data?: object) => {
            try {
                const config = {
                    headers: {Authorization: `Bearer ${azureToken}`},
                };
                let response: FetchResponse = {};
                if (data) {
                    response = (await axios.post(url as string, data, config))
                        .data;
                } else {
                    response = (await axios.get(url as string, config)).data;
                }
                return response;
            } catch (error: unknown) {
                const customError = error as CustomError;

                if (customError.response?.status === 404) {
                    console.error(`Resource not found: ${url}`, error);

                    return null;
                } else if (customError.response?.status === 403) {
                    handleRefreshAzureToken(accessToken);
                } else {
                    setDialogTitle(`Error ${customError.response?.status}`);
                    setAlertMessage(
                        customError.message || 'Something went wrong'
                    );
                    handleOpenDialog();
                }

                throw error;
            }
        },
        [
            azureToken,
            accessToken,
            handleRefreshAzureToken,
            setDialogTitle,
            setAlertMessage,
            handleOpenDialog,
        ]
    );

    const powerBiConfigs = useMemo(() => {
        return reportIds
            .map((id, index) => ({
                type: 'report',
                id,
                embedUrl: embeddedUrls[index] ?? '',
                accessToken: authTokens[index] ?? '',
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false,
                        },
                    },
                    background: models.BackgroundType.Transparent,
                },
                reportsName: config.reportsName[index],
            }))
            .filter((config) => config.embedUrl && config.accessToken);
    }, [authTokens, embeddedUrls, reportIds]);

    useEffect(() => {
        const fetchAll = async () => {
            const newAuthTokens = [];
            const newEmbeddedUrls = [];

            for (const id of reportIds) {
                try {
                    const currentBaseUrl = `${config.powerBiUrl + id}`;
                    const embedData = await fetchData(currentBaseUrl);
                    const tokenData = await fetchData(
                        `${currentBaseUrl}/GenerateToken`,
                        {
                            accessLevel: 'view',
                            allowSaveAs: false,
                        }
                    );

                    newEmbeddedUrls.push(embedData?.embedUrl ?? null);
                    newAuthTokens.push(tokenData?.token ?? null);
                } catch (error) {
                    console.error(
                        `Error fetching data for report ID ${id}:`,
                        error
                    );

                    newEmbeddedUrls.push(null);
                    newAuthTokens.push(null);
                }
            }

            setEmbeddedUrls(newEmbeddedUrls);
            setAuthTokens(newAuthTokens);
            setIsLoading(false);
        };

        setIsLoading(true);
        fetchAll();
    }, [fetchData, reportIds, setEmbeddedUrls, setAuthTokens, setIsLoading]);

    document.title = `AppHub - Transaction Monitoring`;
    return (
        <Box
            className='powerBiContainerClass'
            sx={{minHeight: '100%', overflowY: 'hidden'}}
        >
            <Tabs
                style={{height: '5%'}}
                value={activeTab}
                onChange={handleTabChange}
            >
                {powerBiConfigs.map((config) => (
                    <Tab key={config.id} label={config.reportsName}/>
                ))}
            </Tabs>
            {isLoading ? (
                <Loading/>
            ) : (
                activeTab < powerBiConfigs.length &&
                renderPowerBiEmbed(powerBiConfigs[activeTab])
            )}
            <ResponsiveDialog
                redirectButton={false}
                alertMessage={alertMessage}
                dialogTitle={dialogTitle}
                isDialogOpen={isDialogOpen}
                handleCloseDialog={handleCloseDialog}
                handleRedirect={handleLogOut}
            />
        </Box>
    );
}
