import React, {useEffect} from 'react';
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from '@tiptap/starter-kit';
import { Controller } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import FooterNote from '../footer-note/footer-note';
import "./CustomTextAreaTipTap.css";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Code from "@tiptap/extension-code";
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { Data } from '../../types/shared';
import * as Icons from "./Icons";
import classNames from "classnames";

function CustomTextarea({ control, name, labelName, updateDate, updateName }: Readonly<Data>) {
    const editor = useEditor({
        extensions: [StarterKit, Bold, Underline, Italic, Strike, Code,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }), TextStyle],
        content: ""
    });

    useEffect(() => {
        if (!editor) return;

        const handleUpdate = () => {
            const html = editor.getHTML();
            control._formValues[name] = html === "<p></p>" ? "" : html;
        };

        editor.on('update', handleUpdate);
        return () => {
            editor.off('update', handleUpdate);
        };
    }, [editor, control, name]);

    useEffect(() => {
        if (!editor) return;

        const currentContent = editor.getHTML();
        const fieldValue = control._formValues[name] || "";

        if (fieldValue !== currentContent) {
            editor.commands.setContent(fieldValue, false);
        }
    }, [editor, control._formValues[name]]);

    if (!editor) {
        return <div>Loading editor...</div>;
    }

    const toggleUndo = () => {
        editor.chain().focus().undo().run();
    };

    const toggleRedo = () => {
        editor.chain().focus().redo().run();
    };

    const toggleBold = () => {
        editor.chain().focus().toggleBold().run();
    };

    const toggleUnderline = () => {
        editor.chain().focus().toggleUnderline().run();
    };

    const toggleItalic = () => {
        editor.chain().focus().toggleItalic().run();
    };

    const toggleStrike = () => {
        editor.chain().focus().toggleStrike().run();
    };

    const toggleCode = () => {
        editor.chain().focus().toggleCode().run();
    };

    const textAlignLeft = () => {
        editor.chain().focus().setTextAlign('left').run();
    };

    const textAlignCenter = () => {
        editor.chain().focus().setTextAlign('center').run();
    };

    const textAlignRight = () => {
        editor.chain().focus().setTextAlign('right').run();
    };

    const textAlignJustify = () => {
        editor.chain().focus().setTextAlign('justify').run();
    };

    const toggleBulletList = () => {
        editor.chain().focus().toggleBulletList().run();
    };

    const toggleOrderedList = () => {
        editor.chain().focus().toggleOrderedList().run();
    };

    return (
        <Grid flexDirection={'column'} container item gap={2}>
            <Typography variant='h6'>{labelName}</Typography>
            <div className="editor">
                <div className="menu">
                    <button
                        type={"button"}
                        className="menu-button"
                        onClick={toggleUndo}
                        disabled={!editor.can().undo()}
                    >
                        <Icons.RotateLeft />
                    </button>
                    <button
                        type={"button"}
                        className="menu-button"
                        onClick={toggleRedo}
                        disabled={!editor.can().redo()}
                    >
                        <Icons.RotateRight />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("bold")
                        })}
                        onClick={toggleBold}
                    >
                        <Icons.Bold />
                    </button>

                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("underline")
                        })}
                        onClick={toggleUnderline}
                    >
                        <Icons.Underline />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("italic")
                        })}
                        onClick={toggleItalic}
                    >
                        <Icons.Italic />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("strike")
                        })}
                        onClick={toggleStrike}
                    >
                        <Icons.Strikethrough />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("code")
                        })}
                        onClick={toggleCode}
                    >
                        <Icons.Code />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive({ textAlign: 'left' })
                        })}
                        onClick={textAlignLeft}
                    >
                        <Icons.AlignLeft />
                    </button>

                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive({ textAlign: 'center' })
                        })}
                        onClick={textAlignCenter}
                    >
                        <Icons.AlignCenter />
                    </button>

                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive({ textAlign: 'right' })
                        })}
                        onClick={textAlignRight}
                    >
                        <Icons.AlignRight />
                    </button>

                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive({ textAlign: 'justify' })
                        })}
                        onClick={textAlignJustify}
                    >
                        <Icons.AlignJustify />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("bulletList")
                        })}
                        onClick={toggleBulletList}
                    >
                        <Icons.BulletList />
                    </button>
                    <button
                        type={"button"}
                        className={classNames("menu-button", {
                            "is-active": editor.isActive("orderedList")
                        })}
                        onClick={toggleOrderedList}
                    >
                        <Icons.OrderedList />
                    </button>
                </div>
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => {
                        const { onChange, value = "<p></p>" } = field;

                        return (
                            <EditorContent editor={editor} />
                        );
                    }}
                />
                <FooterNote date={updateDate} name={updateName} />
            </div>
        </Grid>
    );
}

export default CustomTextarea;