import PropTypes from 'prop-types';
import { Controller, Control } from 'react-hook-form';
import TextField from '@mui/material/TextField';

interface SearchFieldProps {
    placeholder: string;
    control: Control<any>;
    name: string;
}

const SearchField: React.FC<SearchFieldProps> = ({ placeholder, control, name }) => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <TextField
                {...field}
                variant="outlined"
                placeholder={placeholder}
                size="small"
                onFocus={(e) => e.target.select()}
            />
        )}
    />
);

SearchField.propTypes = {
    placeholder: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
};

export default SearchField;