import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import { Data } from '../../types/shared';

function DatePickerInfo({ labelName, name, control }: Readonly<Data>) {
    return (
        <Controller
            name={
                name as
                    | 'referenceDate'
                    | 'companyInceptionDate'
                    | 'dateOfUploadFile'
                    | 'searchByDateOfUploadedFile'
            }
            control={control}
            render={({ field }) => (
                <DatePicker
                    sx={{ width: '100%' }}
                    label={labelName}
                    {...field}
                />
            )}
        />
    );
}

export default DatePickerInfo;
