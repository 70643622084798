import { Box, Button, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET_PROVIDER_EMAILS } from '../../graphql/queries/emails';
import { useMutation, useQuery } from '@apollo/client';
import Loading from '../loading/loading';
import {
    SEND_GENERATION,
    SEND_GENERATION_ITEM,
} from '../../graphql/mutations/reports';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUIControlContext } from '../../context/ui-control-context';
import { ProviderEmailData } from '../../types/email';
import { IFormInputs } from '../../types/shared';
import { useDataProcessingContext } from '../../context/data-processing-context';
import { columns } from '../../data/columns/providers-info';
import ReturnButton from '../../components/return-button/return-button';
import ErrorPage from '../error/error-page';
import Toast from '../../components/toast/toast';
import SimpleBackdrop from '../../components/backdrop/backdrop';
import CustomTable from '../../components/custom-table/custom-table';
import TextInput from '../../components/text-input/text-input';

function ProvidersInfo() {
    const { selected, setSelected, handleGetIdAndType, groupId, typeId } =
        useDataProcessingContext();
    const {
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        openBackdrop,
        handleOpenBackdrop,
        handleCloseBackdrop,
        groupNameUnique,
        reportDetailId,
        handleManageToast,
    } = useUIControlContext();
    const { reportId } = useParams();

    const [providerEmails, setProviderEmails] = useState<ProviderEmailData[]>(
        []
    );
    const { handleSubmit, control, reset } = useForm<IFormInputs>({
        defaultValues: {
            addEmail: '',
        },
    });

    const { data, loading } = useQuery(GET_PROVIDER_EMAILS, {
        variables: { generationItemId: groupId, generationTypeId: typeId },
    });

    const [SendGeneration, { loading: loadingSendGeneration }] =
        useMutation(SEND_GENERATION);

    const [SendGenerationItem, { loading: loadingSendGenerationItem }] =
        useMutation(SEND_GENERATION_ITEM);

    const rows = useMemo(() => {
        if (groupNameUnique) {
            return providerEmails
                ?.filter(({ groupName }) => {
                    const cleanedGroupName = groupName.replace(/[()+-,-]/g, '');

                    return cleanedGroupName === groupNameUnique;
                })
                ?.map(({ emailList, groupName }) => {
                    return {
                        groupName: groupName,
                        emailList: emailList,
                    };
                });
        } else {
            return providerEmails?.map(({ emailList, groupName }) => {
                return {
                    groupName: groupName,
                    emailList: emailList,
                };
            });
        }
    }, [providerEmails, groupNameUnique]);

    const onSubmit: SubmitHandler<IFormInputs> = useCallback(
        async ({ addEmail }) => {
            handleOpenBackdrop();
            const trimmedEmail = addEmail ? addEmail.replace(/\s+/g, '') : null;
            try {
                if (reportDetailId) {
                    await SendGenerationItem({
                        variables: {
                            generationId: reportDetailId,
                            emailRecipients: trimmedEmail,
                        },
                    });
                } else {
                    await SendGeneration({
                        variables: {
                            emailRecipients: trimmedEmail,
                            generationId: groupId,
                        },
                    });
                }

                reset();
                handleCloseBackdrop();
                handleManageToast('success', 'Email(s) sent successfully');
            } catch (error) {
                reset();
                handleCloseBackdrop();
                handleManageToast(
                    'error',
                    'The was an unexpected error sending the report'
                );
            }
        },
        [
            SendGeneration,
            SendGenerationItem,
            groupId,
            handleCloseBackdrop,
            handleOpenBackdrop,
            reportDetailId,
            reset,
            handleManageToast,
        ]
    );

    useEffect(() => {
        try {
            if (data) {
                setProviderEmails(data.providerEmails);
            }
        } catch (error) {
            console.log('error-getting-providers-info', error);
        }
    }, [data]);

    useEffect(() => {
        if (reportId) {
            handleGetIdAndType(reportId);
        }
    }, [handleGetIdAndType, reportId]);

    if (loading) return <Loading />;

    return (
        <Box
            onSubmit={handleSubmit(onSubmit)}
            component={'form'}
            sx={{ width: '100%' }}
        >
            <Grid container gap={2} flexDirection='column'>
                <Grid item>
                    <ReturnButton />
                </Grid>

                {providerEmails.length > 0 ? (
                    <>
                        <Grid item>
                            <Typography color='#F49F6C' fontWeight='bold'>
                                Please review the emails or add new ones
                            </Typography>
                        </Grid>
                        <Grid item container gap={2} alignItems='center'>
                            <Grid item sx={{ width: '100%' }} textAlign='right'>
                                <Button
                                    type='submit'
                                    color='success'
                                    variant='contained'
                                    sx={{ width: '30%' }}
                                    disabled={
                                        loadingSendGeneration ||
                                        loadingSendGenerationItem
                                    }
                                >
                                    Confirm
                                </Button>
                            </Grid>
                            <TextInput
                                control={control}
                                name='addEmail'
                                labelName='Add Email'
                                placeholder='Add emails separated by ";" and ending in "@allfunds"'
                            />
                            <Grid item sx={{ width: '100%' }}>
                                <CustomTable
                                    useCheckbox={false}
                                    selected={selected}
                                    setSelected={setSelected}
                                    columns={columns}
                                    rows={rows}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <ErrorPage errorMessage='There are no reports to show' />
                )}
            </Grid>
            <Toast
                handleCloseToast={handleCloseToast as () => void}
                openToast={openToast as boolean}
                severity={alertSeverity}
                message={alertMessage}
            />

            <SimpleBackdrop
                openBackdrop={openBackdrop as boolean}
            />
        </Box>
    );
}

export default ProvidersInfo;
